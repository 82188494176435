import { useState } from "react";
import { ACTION, AFTER, BEFORE, VOLUNTEERS } from "../../util/CommonConstants";

export const CleanupPhotoForm = (props) => {
  const [beforePhoto, setBeforePhoto] = useState(null);
  const [afterPhoto, setAfterPhoto] = useState(null);
  const [actionPhoto, setActionPhoto] = useState(null);
  const [volunteersPhoto, setVolunteersPhoto] = useState(null);

  return (
    <div>
      <h2>Add Photos To Your Cleanup</h2>
      <div style={{ display: "flex", justifyContent: "space-between" , flexDirection: "column"}}>
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <label className="form-label" style={{ fontWeight: "bold" }}>
            Before Cleanup Photo
          </label>
          <br />
          {beforePhoto && (
            <div>
              <img
                alt="not found"
                width={"250px"}
                src={URL.createObjectURL(beforePhoto)}
              />
              <br />
              <button onClick={() => setBeforePhoto(null)}>Remove</button>
            </div>
          )}
          <input
            type="file"
            name="beforePhoto"
            onClick={(event) => {
              console.log(event);
            }}
            onChange={(event) => {
              console.log(event.target.files[0]);
              setBeforePhoto(event.target.files[0]);
              props.updatePhotoMapFunction(BEFORE, event.target.files[0]);
            }}
          />
          <br />
          <br />

          <label className="form-label" style={{ fontWeight: "bold" }}>
            After Cleanup Photo
          </label>
          <br />
          {afterPhoto && (
            <div>
              <img
                alt="not found"
                width={"250px"}
                src={URL.createObjectURL(afterPhoto)}
              />
              <br />
              <button onClick={() => setAfterPhoto(null)}>Remove</button>
            </div>
          )}
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              console.log(event.target.files[0]);
              setAfterPhoto(event.target.files[0]);
              props.updatePhotoMapFunction(AFTER, event.target.files[0]);
            }}
          />
          <br />
          <br />
        </div>

        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <label className="form-label" style={{ fontWeight: "bold" }}>
            Action Cleanup Photo
          </label>
          <br />
          {actionPhoto && (
            <div>
              <img
                alt="not found"
                width={"250px"}
                src={URL.createObjectURL(actionPhoto)}
              />
              <br />
              <button onClick={() => setActionPhoto(null)}>Remove</button>
            </div>
          )}
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              console.log(event.target.files[0]);
              setActionPhoto(event.target.files[0]);
              props.updatePhotoMapFunction(ACTION, event.target.files[0]);
            }}
          />
          <br />
          <br />

          <label className="form-label" style={{ fontWeight: "bold" }}>
            Volunteers Cleanup Photo
          </label>
          <br />
          {volunteersPhoto && (
            <div>
              <img
                alt="not found"
                width={"250px"}
                src={URL.createObjectURL(volunteersPhoto)}
              />
              <br />
              <button onClick={() => setVolunteersPhoto(null)}>Remove</button>
            </div>
          )}
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              console.log(event.target.files[0]);
              setVolunteersPhoto(event.target.files[0]);
              props.updatePhotoMapFunction(VOLUNTEERS, event.target.files[0]);
            }}
          />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
