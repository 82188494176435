import { Grid, Row, Col } from "react-flexbox-grid";
import { useHistory } from "react-router-dom";
import banner from "../../static/img/banner.jpg";
import appStoreBadge from "../../static/img/app-store-badge.png";
import googlePlayBadge from "../../static/img/google-play-badge.jpg";

export default function LandingScreen() {
  const history = useHistory();
  return (
    <div className="App" style={{ marginBottom: 100 }}>
      <img src={banner} className="banner" alt="banner" width="100%"></img>
      <h2 style={{ marginTop: 20 }}>Welcome to the 808 Cleanups Web App!</h2>
      <p style={{ width: "60%", margin: "auto", paddingBottom: 50 }}>
        This website is an extension of the 808 Cleanups Mobile App available on
        the Google Play Store and Apple's App Store, but able to run on any
        device with a web browser!
      </p>
      <div style={{ paddingBottom: 50 }}>
        <a href="https://apps.apple.com/us/app/808-cleanups/id1464605740">
          <img
            src={appStoreBadge}
            style={{ margin: 10 }}
            alt="appStore"
            width="25%"
          ></img>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.app808cleanups&hl=en_US">
          <img
            src={googlePlayBadge}
            style={{ margin: 10 }}
            alt="playStore"
            width="25%"
          ></img>
        </a>
      </div>
      <Grid>
        <Row>
          <Col>
            <h2>Get Involved!</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              className="btn btn-primary"
              style={{ margin: 10, width: "50%" }}
              onClick={() => history.push("/cleanup/log")}
            >
              Log a Cleanup
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              className="btn btn-primary"
              style={{ margin: 10, width: "50%", marginBottom: 50 }}
              onClick={() => history.push("/event/create")}
            >
              Schedule an Event
            </button>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
