import { isLogin, TOKEN_KEY } from "../util/Auth";
import "../static/css/NavBar.css";
import logo from "../static/img/logo.png";
import { useEffect, useState } from "react";
import OrganizationsColl from "../firebase/OrganizationsColl";
import Select from "react-select";
import { ORG_ID, ORG_NAME } from "../util/CommonConstants";

export default function NavBar() {
  const currentUser = JSON.parse(localStorage.getItem(TOKEN_KEY));

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (currentUser) {
      async function fetchOrganizations() {
        let orgList = [];
        for (let org of currentUser.organizations) {
          //get organization info
          let firebaseOrg = await OrganizationsColl.shared.getOrganizationById(
            org
          );
          orgList.push(firebaseOrg);
        }

        if (localStorage.getItem(ORG_ID) === null) {
          localStorage.setItem(ORG_ID, orgList[0].id);
        }
        if (localStorage.getItem(ORG_NAME) === null) {
          localStorage.setItem(ORG_NAME, orgList[0].org_name);
        }
        updateActiveOrganization({
          value: localStorage.getItem(ORG_ID),
          label: localStorage.getItem(ORG_NAME)
        });

        setOrganizations(orgList);
      }
      fetchOrganizations();
    }
  }, []);

  const updateActiveOrganization = (e) => {
    localStorage.setItem(ORG_ID, e.value);
    localStorage.setItem(ORG_NAME, e.label);
    checkAdmin(e.value);
    console.log("Active Organization: " + e.label);
  };

  const [isAdmin, setIsAdmin] = useState(false);

  async function checkAdmin(org) {
    if (currentUser) {
      let firebaseOrg = await OrganizationsColl.shared.getOrganizationById(org);
      setIsAdmin(
        firebaseOrg.org_admins &&
          firebaseOrg.org_admins.includes(currentUser.id)
      );
    }
  }

  useEffect(() => {
    const orgId = localStorage.getItem(ORG_ID);
    checkAdmin(orgId);
  }, [currentUser, organizations]);

  const isMobile = width <= breakpoint;

  if (!isMobile) {
    return (
      <div>
        <div id="sidenav">
          <img
            src={logo}
            className="logo"
            alt="logo"
            style={{ width: "80%", height: "auto", paddingTop: "10px" }}
          ></img>
          {currentUser ? <p>Aloha, {currentUser.first_name}!</p> : null}

          {organizations.length > 0 ? (
            <div>
              <p style={{ color: "white" }}>Organization</p>
              <Select
                className="basic-single"
                options={organizations.map((org) => {
                  return { value: org.id, label: org.org_name };
                })}
                defaultValue={{
                  value: localStorage.getItem(ORG_ID),
                  label: localStorage.getItem(ORG_NAME)
                }}
                onChange={updateActiveOrganization}
                placeholder="Select"
              />
              <hr />
            </div>
          ) : null}

          <a href="/">
            <i className="fas fa-home"></i> Home
          </a>
          <a href="/cleanup/log">
            <i className="fas fa-wrench"></i> Log Cleanup
          </a>
          <a href="/event/create">
            <i className="fas fa-calendar-plus" aria-hidden="true"></i> Schedule
            Event
          </a>
          <a href="/calendar">
            <i className="fas fa-calendar-alt"></i> Calendar
          </a>
          <a href="/organizations">
            <i className="fas fa-globe"></i> Organizations
          </a>

          <a href="mailto://808cleanups@gmail.com">
            <i className="fas fa-envelope"></i> Contact Us
          </a>

          {isAdmin ? (
            <a href="/admin">
              <i className="fas fa-user-shield"></i> Admin
            </a>
          ) : null}

          {isLogin() ? (
            <div>
              <a href="/logout" className="sidenavbottom">
                Logout
              </a>
            </div>
          ) : null}
        </div>

        <ul style={{ padding: 10 }}>
          <li style={{ float: "right" }}></li>
        </ul>
      </div>
    );
  } else {
    return (
      <div>
        <div id="bottomnav-header">
          <img src={logo} className="logo" alt="logo"></img>

          {currentUser ? <p>Aloha, {currentUser.first_name}!</p> : null}

          {organizations.length > 0 ? (
            <div>
              <Select
                className="basic-single"
                options={organizations.map((org) => {
                  return { value: org.id, label: org.org_name };
                })}
                defaultValue={{
                  value: localStorage.getItem(ORG_ID),
                  label: localStorage.getItem(ORG_NAME)
                }}
                onChange={updateActiveOrganization}
                placeholder="Organization"
              />
              <hr />
            </div>
          ) : null}
        </div>

        <div id="bottomnav">
          <a href="/">
            <i className="fas fa-home"></i>
            <p>Home</p>
          </a>
          <a href="/cleanup/log">
            <i className="fas fa-wrench"></i>
            <p>Log</p>
          </a>
          <a href="/event/create">
            <i className="fas fa-calendar-plus"></i>
            <p>Schedule</p>
          </a>
          <a href="/calendar">
            <i className="fas fa-calendar-alt"></i>
            <p>Calendar</p>
          </a>
          <a href="/organizations">
            <i className="fas fa-globe"></i> Groups
          </a>
          <a href="mailto://808cleanups@gmail.com">
            <i className="fas fa-envelope"></i>
            <p>Contact</p>
          </a>
        </div>
      </div>
    );
  }
}
