export const CleanupConfirmationScreen = (props) => {
  return (
    <div className="container mt-5">
      <h1>Mahalo Nui Loa!</h1>
      <h3>
        Your cleanup has been submitted for approval by 808 Cleanups staff!
      </h3>
      <br />
      {props.site_name ? <h2>{props.site_name}</h2> : null}
      <a href="/">
        {" "}
        <button className="btn btn-primary">Done</button>
      </a>
    </div>
  );
};
