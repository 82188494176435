import Fire from "./Fire";
import {
  COLL_MSG_USER,
  COLL_MSG_UNREAD_LOG,
  ADMIN
} from "../util/CommonConstants";
import { TOKEN_KEY } from "../util/Auth";
import UsersColl from "./UsersColl";
import UtilDate from "../util/UtilDate";
import { createRandomId } from "../util/Util";

var db;

class MessagesUserColl {
  constructor() {
    db = Fire.shared.getDatabase();
  }

  async getFirebaseDatabase() {
    if (!Fire.shared.hasBeenInitialized()) {
      await Fire.shared.establishFirebase();
    }
    return Fire.shared.getDatabase();
  }

  async createAdminMessage(item) {
    if (!item) {
      return false;
    }

    if (!db) {
      db = await this.getFirebaseDatabase();
    }

    if (!db) {
      return false;
    }

    const user = JSON.parse(localStorage.getItem(TOKEN_KEY));
    const userId = user.id;

    const adminUser = await UsersColl.shared.getUser(ADMIN);

    const user_list = [
      {
        user_name: user.first_name + " " + user.last_name,
        user_id: userId
      },
      {
        user_name: adminUser.first_name + " " + adminUser.last_name,
        user_id: ADMIN
      }
    ];

    let user_ids = [userId, ADMIN];

    let currentMsg = {
      from_user_id: userId,
      message_text: item.message,
      create_date: new Date()
    };

    let message = {
      subject: item.subject,
      user_list,
      user_ids,
      messages: [currentMsg]
    };

    let dateId = UtilDate.getDateShortOnlyNumber(new Date());
    let subId = item.subject;
    let subIdList = item.subject.split(/ +/);

    if (subIdList && subIdList.length > 1) {
      subId = subIdList.join("").substring(0, 10);
    }

    message.id =
      userId +
      "_" +
      dateId +
      "_" +
      subId +
      "_" +
      createRandomId().substring(0, 3);
    message.create_date = new Date();
    message.last_update_date = message.create_date;

    let batch = db.batch();

    let msgObj = Object.assign({}, message);
    delete msgObj.id;

    let msgRef = db.collection(COLL_MSG_USER).doc(message.id);
    batch.set(msgRef, msgObj);

    //this is for unread log for admin
    let idRef = db.collection(COLL_MSG_UNREAD_LOG).doc();
    batch.set(idRef, {
      msg_id: message.id,
      user_id: ADMIN,
      create_date: new Date()
    });

    try {
      await batch.commit();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}

MessagesUserColl.shared = new MessagesUserColl();

export default MessagesUserColl;
