import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#46c965" },
    secondary: { main: "#c95146" }
  }
});

export default theme;
