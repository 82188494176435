import { EVENT_COLL } from "../util/CommonConstants";
import Fire from "./Fire";
import { createRandomId } from "../util/Util";

var collectionName = EVENT_COLL;

class EventColl {
  async getFirebaseDatabase() {
    if (!Fire.shared.hasBeenInitialized()) {
      await Fire.shared.establishFirebase();
    }
    return Fire.shared.getDatabase();
  }

  async createEvent(event, approved = false) {
    if (!event) {
      return false;
    }

    try {
      if (!event.id) {
        let siteName = event.title.replace(/\//g, "");
        let siteId = siteName.replace(/\s/g, "").substring(0, 10);

        event.id = siteId + "_" + createRandomId();
      }

      event.organization = event.organization;

      if (!event.rsvp_list) {
        event.rsvp_list = [];
      }
    } catch (error) {
      console.log(error);
      return false;
    }

    let approvedCollName = collectionName;
    if (!approved) approvedCollName = collectionName + "_unapproved";

    try {
      var response = await Fire.shared.createSingleDocumentById(
        event,
        approvedCollName
      );

      if (response === true) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}

EventColl.shared = new EventColl();

export default EventColl;
