import OrganizationsColl from "../../firebase/OrganizationsColl";
import UsersColl from "../../firebase/UsersColl";
import { TOKEN_KEY } from "../../util/Auth";
import { useEffect, useState } from "react";
import "../../static/css/OrganizationsScreen.css";
import logo from "../../static/img/logo.png";
import { useHistory } from "react-router-dom";

export const OrganizationsScreen = () => {
  const currentUser = JSON.parse(localStorage.getItem(TOKEN_KEY));
  const history = useHistory();

  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (currentUser) {
      async function fetchOrgs() {
        let orgs = await OrganizationsColl.shared.getAllOrganizations();

        for (let org of orgs) {
          if (currentUser.organizations.includes(org.id)) {
            org["isMember"] = true;
          }
        }
        setOrganizations(orgs);
      }
      fetchOrgs();
    }
  }, []);

  async function addUserToOrganization(orgId) {
    let currentUser = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if (currentUser.organizations.includes(orgId)) {
      return;
    }

    let organizations = currentUser.organizations;
    organizations.push(orgId);
    currentUser.organizations = organizations;
    localStorage.setItem(TOKEN_KEY, JSON.stringify(currentUser));

    await UsersColl.shared.updateUserOrganizations(
      currentUser.id,
      currentUser.organizations
    );
    history.push("/organizations/joined?orgid=" + orgId);
  }

  async function removeUserFromOrganization(orgId) {
    let currentUser = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if (!currentUser.organizations.includes(orgId)) {
      return;
    }

    let organizations = currentUser.organizations;
    organizations = organizations.filter((org) => org !== orgId);
    currentUser.organizations = organizations;
    localStorage.setItem(TOKEN_KEY, JSON.stringify(currentUser));

    await UsersColl.shared.updateUserOrganizations(
      currentUser.id,
      currentUser.organizations
    );
    window.location.reload();
  }

  return (
    <div>
      <h1>Organizations</h1>
      <div className="card-container">
        <h3>Your Organizations</h3>
        {organizations.map((org) =>
          org.isMember ? (
            <div className="card" key={org.id}>
              <h2>{org.org_name}</h2>
              {org.org_logo && org.org_logo.length > 0 ? (
                <img src={org.org_logo} alt="logo" />
              ) : (
                <img src={logo} alt="logo" />
              )}
              <p>
                {!org.org_description || org.org_description.length <= 150
                  ? org.org_description
                  : org.org_description.substring(0, 150)}
              </p>
              <button onClick={() => removeUserFromOrganization(org.id)}>
                Leave
              </button>
            </div>
          ) : null
        )}
      </div>

      <div className="card-container">
        <h3>Join an Organization!</h3>
        {organizations.map((org) =>
          !org.isMember ? (
            <div className="card" key={org.id}>
              <h2>{org.org_name}</h2>
              {org.org_logo && org.org_logo.length > 0 ? (
                <img src={org.org_logo} alt="logo" />
              ) : (
                <img src={logo} alt="logo" />
              )}
              <p>
                {!org.org_description || org.org_description.length <= 150
                  ? org.org_description
                  : org.org_description.substring(0, 150)}
              </p>
              <button onClick={() => addUserToOrganization(org.id)}>
                Join
              </button>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};
