export const APP_DEBUG = false;

export var CLEANUP_COLL = "cleanups";
export var EVENT_COLL = "events";
export var COLL_MSG_USER = "messages_user";
export var COLL_MSG_UNREAD_LOG = "messages_unread_log";
export var COLL_ORGANIZATIONS = "organizations";

export let ADMIN = "MichaelLoftin_176861";
export let ORG_ID = "activeOrgId";
export let ORG_NAME = "activeOrgName";

if (APP_DEBUG) {
  CLEANUP_COLL = "cleanups_test";
  EVENT_COLL = "events_test";
  COLL_MSG_USER = "messages_user_test";
  COLL_MSG_UNREAD_LOG = "messages_unread_log_test";

  ADMIN = "JustinMiller_159740231";
}

export const FIREBASE_API_KEY = "AIzaSyDrkRYiwstuFXLucFbVf8Xn9ENg7ktiijU";
// export const FIREBASE_API_KEY = "AIzaSyCwfbeDLYzOjeflY6Nu87-vAjYvJOhKI3c";

export const NO_CONN = "No Connection";

export const TRASH = "Trash";
export const RECYCLING = "Recycling";
export const GRAFFITI = "Graffiti Tags";
export const CIGS = "Cigarettes";
export const MARINE_DEBRIS = "Marine Debris";
export const LINE = "Fishing Line";
export const LEAD = "Lead Weight";
export const NET = "Derelict Net(s)";
export const NAILS = "Pallet Bonfire Nails";
export const INV_SPECIES = "Invasive Species";
export const NATIVE_PLANTING = "Native Planting";
export const SYRINGES = "Syringes";
export const CARTS = "Carts";
export const UNBURNED_PALLETS = "Unburned Pallets";
export const TIRES = "Tires";
export const TARO_PLANTED = "Taro Planted";
export const TARO_HARVESTED = "Taro Harvested";
export const TARO = "Taro";
export const KUAPA_STACKING = "Kuapā Stacking";
export const LIMU_PLANTED = "Limu Planted";

export const WEIGHT = "Weight (lbs)";
export const QUANTITY = "Quantity";
export const LENGTH = "Length (ft)";
export const SQ_FT = "Sq Feet";
export const CU_FT = "Cu Feet";

export const debrisTypeList = [
  TRASH,
  RECYCLING,
  GRAFFITI,
  CIGS,
  MARINE_DEBRIS,
  LINE,
  LEAD,
  NET,
  NAILS,
  UNBURNED_PALLETS,
  TIRES,
  SYRINGES,
  CARTS,
  INV_SPECIES,
  NATIVE_PLANTING,
  TARO_PLANTED,
  TARO_HARVESTED,
  TARO,
  KUAPA_STACKING,
  LIMU_PLANTED
];

export const debrisMeasurement = { WEIGHT, QUANTITY, LENGTH, SQ_FT, CU_FT };

export const debrisValueMap = {
  [TRASH]: WEIGHT,
  [RECYCLING]: WEIGHT,
  [GRAFFITI]: QUANTITY,
  [CIGS]: QUANTITY,
  [MARINE_DEBRIS]: WEIGHT,
  [LINE]: LENGTH,
  [LEAD]: WEIGHT,
  [NET]: WEIGHT,
  [NAILS]: WEIGHT,
  [UNBURNED_PALLETS]: QUANTITY,
  [TIRES]: QUANTITY,
  [SYRINGES]: QUANTITY,
  [CARTS]: QUANTITY,
  [INV_SPECIES]: SQ_FT,
  [NATIVE_PLANTING]: SQ_FT,
  [TARO_PLANTED]: QUANTITY,
  [TARO_HARVESTED]: QUANTITY,
  [TARO]: WEIGHT,
  [KUAPA_STACKING]: CU_FT,
  [LIMU_PLANTED]: WEIGHT
};

export const BEFORE = "before";
export const AFTER = "after";
export const ACTION = "action";
export const VOLUNTEERS = "volunteers";

export const activityOptions = [
  "Beach Cleanup",
  "Underwater Cleanup",
  "Marine Debris Cleanup",
  "Derelict Fishing Gear Cleanup",
  "Park Cleanup",
  "Trail Cleanup",
  "Pallet Bonfire Removal",
  "Urban Cleanup",
  "Graffiti Removal",
  "Stream Cleanup",
  "Abandoned Camp Cleanup",
  "Illegal Dumping Cleanup",
  "Invasive Species Removal",
  "Native Planting",
  "Harbor Cleanup",
  "Loʻi Restoration",
  "Kuapā Restoration",
  "Limu Restoration"
];

//
// Messages
//

export const ON_ERROR_CONTACT =
  "If the problem persists, please contact 808CleanupsApp@gmail.com.";

export const WHOLE_NUMBER_ERR = "The value must be a whole number";
export const NUMBER_ERR = "The value must be a number";
export const MISSING_VALUE_ERR = "You are missing the value for: ";

export const SAVING_CHANGES_MSG = "Hang tight, we're saving your changes...";

export const PROC_SUCCESS_MSG = "Your request has been saved!";

export const SAVE_SUCCESS_MSG = "Your information has been saved!";

export const SUCCESS_TITLE = "Success";

export const ERR_TITLE = "Something happened";

export const SAVE_ERR_MSG =
  "Humm...something went wrong while trying to save your information. Your information was not saved. Please try again. " +
  ON_ERROR_CONTACT;

export const SEND_ADMIN_MSG_SUCCESS =
  "We sent the organizer a message with your request! They will respond once they receive the message.";

export const SEND_ADMIN_MSG_ERR =
  "Humm...something went wrong while trying to send the admin a message with your request. Please send a message through this app to Michael Loftin with your request.";

export const SEND_ADMIN_MSG_REQUEST_ERR =
  "Please send a message through this app to Michael Loftin with your request.";

export const SEND_MSG_SUCCESS = "Your message has been sent!";

export const SEND_ERR_MSG =
  "Humm...something went wrong while trying to send your message. Your message was not sent. Please try again. " +
  ON_ERROR_CONTACT;

export const PROC_ERR_MSG =
  "Humm...something went wrong while processing your request. Please try again. " +
  ON_ERROR_CONTACT;

export const NO_SERVICE_ERR_MSG =
  "We can't connect to the database right now. Please check your service and try again later.";

export const EMAIL_ERR_MSG =
  "Humm...something went wrong while trying to open your email. Please manually submit an email to 808Cleanups@gmail.com with the details.";

export const reportSomethingSuccessMessage =
  'MAHALO NUI LOA for your help! Don\'t forget to "Log Your Cleanup Data" and SHARE photos on Instagram and/or Facebook when you are done!';

export const reportSomethingSuccessNoCleanupMessage =
  "MAHALO NUI LOA for your report! Your report has been submitted!";

export const CLEAN_UP_MSG =
  "You are AWESOME! Mahalo for your hard work. You really made a difference. If it wasn't for your cleanup, this stuff would still be there. Don't forget to SHARE on Instagram and/or Facebook too!";

export const SAVE_REQUEST_SUPPORT_MSG =
  "We're sending out an alert to the 808 Cleanups ’ohana! MAHALO NUI LOA for helping keep Hawai'i clean!";

export const ERR_MAP_TITLE = "Cannot open map";
export const ERR_MAP_MSG =
  "We cannot open your map at this time. Please try again. " + ON_ERROR_CONTACT;

export const SEND_MSG_PROC_MSG =
  "We're sending your message! Please hang on, this may take a moment...";

export const PEOPLE_SEARCH_MSG = "Looking for other cool volunteers like you!";

export const CANNOT_RETRIEVE_MESSAGES =
  "We are unable to retrieve your messages at this time. Please check your service and try again. " +
  ON_ERROR_CONTACT;

export const CANNOT_RETRIEVE_USERS =
  "We are unable to retrieve other users at this time. Please check your service and try again. " +
  ON_ERROR_CONTACT;

export const RSVP_MESSAGE_SUBJECT = "Event RSVP Confirmation";
