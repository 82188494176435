import React, { useEffect } from "react";
import "./styles.css";
import { logout, TOKEN_KEY } from "../../util/Auth";
import { useHistory } from "react-router-dom";
import { ORG_ID } from "../../util/CommonConstants";

export default function LogoutScreen() {
  const history = useHistory();
  let userLogout = () => {
    logout().then(() => {
      console.log("logged out");
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(ORG_ID);
      history.push("/");
    });
  };

  useEffect(() => {
    userLogout();
  });

  return <div></div>;
}
