class UtilDateCommon {
  convertSecondsToDate(myDate) {
    if (myDate instanceof Date) {
      return myDate;
    }
    if (!myDate) {
      return null;
    }

    if (!(myDate instanceof Date)) {
      try {
        let date = new Date(myDate.seconds * 1000);
        return date;
      } catch (err) {
        return null;
      }
    }
    return null;
  }

  dateObjToString(myDate) {
    if (!myDate) {
      return "";
    }

    if (!(myDate instanceof Date)) {
      myDate = new Date(myDate.seconds * 1000);
    }

    var hours = myDate.getHours();
    var minutes = myDate.getMinutes();

    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + ampm;

    // e.g. "Jan 11 2019 10:05 am";
    return (
      monthList[myDate.getMonth()] +
      " " +
      myDate.getDate() +
      " " +
      myDate.getFullYear() +
      " " +
      strTime
    );
  }

  subtractDays(myDate, days) {
    let date = new Date(myDate);
    date.setDate(date.getDate() - days);
    return date;
  }

  getMonthValue(monthVal) {
    if (monthVal === 0) {
      return "01";
    }
    if (monthVal === 1) {
      return "02";
    }
    if (monthVal === 2) {
      return "03";
    }
    if (monthVal === 3) {
      return "04";
    }
    if (monthVal === 4) {
      return "05";
    }
    if (monthVal === 5) {
      return "06";
    }
    if (monthVal === 6) {
      return "07";
    }
    if (monthVal === 7) {
      return "08";
    }
    if (monthVal === 8) {
      return "09";
    }
    if (monthVal === 9) {
      return "10";
    }
    if (monthVal === 10) {
      return "11";
    }
    if (monthVal === 11) {
      return "12";
    }
  }

  getDayValue(dayVal) {
    if (parseInt(dayVal) < 10) {
      return "0" + dayVal;
    }

    return dayVal;
  }

  getDateShortOnlyNumber(date) {
    let myDate = this.convertSecondsToDate(date);
    if (!(myDate instanceof Date)) {
      return "";
    }

    // e.g. "052018";
    return (
      this.getMonthValue(myDate.getMonth().toString()) +
      this.getDayValue(myDate.getDate().toString()) +
      myDate.getFullYear().toString().substr(-2)
    );
  }

  getDateShortWithDay(date) {
    let myDate = this.convertSecondsToDate(date);
    if (!(myDate instanceof Date)) {
      return "";
    }

    var day = new Array();
    day[0] = "Sun";
    day[1] = "Mon";
    day[2] = "Tue";
    day[3] = "Wed";
    day[4] = "Thur";
    day[5] = "Fri";
    day[6] = "Sat";

    // e.g. "Tue 4/11";
    return (
      day[myDate.getDay()] +
      " " +
      (myDate.getMonth() + 1) +
      "/" +
      myDate.getDate()
    );
  }

  getTimeFromDate(myDate) {
    try {
      myDate = this.convertSecondsToDate(myDate);
      if (!(myDate instanceof Date)) {
        return "";
      }

      var hours = myDate.getHours();
      var minutes = myDate.getMinutes();

      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      var minuteStr = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minuteStr + ampm;

      // e.g. "9:00am";
      return strTime;
    } catch (e) {
      console.error(e);
    }
  }
}

const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

var UtilDate = new UtilDateCommon();
export default UtilDate;
