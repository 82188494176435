import Fire from "./Fire";
import { COLL_ORGANIZATIONS } from "../util/CommonConstants";
import { TOKEN_KEY } from "../util/Auth";

var collectionName = COLL_ORGANIZATIONS;
var db;

export class OrganizationModel {
  id;
  org_name;
  org_admins; //list of user IDs [user_id, user_id]
  org_description; // note: cap this to 150 characters
  org_logo;
}

class OrganizationsColl {
  constructor() {
    db = Fire.shared.getDatabase();
  }

  async getFirebaseDatabase() {
    if (!Fire.shared.hasBeenInitialized()) {
      await Fire.shared.establishFirebase();
    }
    return Fire.shared.getDatabase();
  }

  async getOrganizationById(id) {
    if (!id) {
      return null;
    }

    let organization = null;

    try {
      organization = await Fire.shared.getSingleDocumentById(
        id,
        collectionName
      );
    } catch (err) {
      console.log(err);
      return false;
    }

    return organization;
  }

  async getAllOrganizations() {
    if (!db) {
      db = await this.getFirebaseDatabase();
    }

    if (!db) {
      return false;
    }

    try {
      let results = null;
      results = await db.collection(collectionName).get();

      if (results) {
        return Fire.shared.getDataArray(results);
      }

      return [];
    } catch ({ message }) {
      console.log(message);
      return false;
    }
  }
}

OrganizationsColl.shared = new OrganizationsColl();

export default OrganizationsColl;
