import React from "react";

export const CheckBoxComponent = ({
  items,
  selectedItems,
  handleCheckBoxChange
}) => {
  return (
    <div className="container" style={{ width: "100%" }}>
      {items.map((item, index) => (
        <div className="form-check" key={index}>
          <input
            className="form-check-input"
            type="checkbox"
            value={item}
            id={`checkbox-${index}`}
            onChange={handleCheckBoxChange}
            checked={selectedItems.includes(item)}
            style={{ marginLeft: "1px" }}
          />
          <label className="form-check-label" htmlFor={`checkbox-${index}`}>
            {item}
          </label>
        </div>
      ))}
    </div>
  );
};

export default CheckBoxComponent;
