import { FIREBASE_API_KEY, NO_CONN, APP_DEBUG } from "../util/CommonConstants";
import { isNullEmpty } from "../util/Util";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

var db;
var EMPTY = "Empty";
var returnCallback;

class Fire {
  async establishFirebase() {
    if (this.hasBeenInitialized) {
      return;
    }

    try {
      if (APP_DEBUG) {
        firebase.initializeApp({
          // Dev firebase
          apiKey: "AIzaSyCwfbeDLYzOjeflY6Nu87-vAjYvJOhKI3c",
          authDomain: "cleanups-1e54a.firebaseapp.com",
          projectId: "cleanups-1e54a",
          storageBucket: "cleanups-1e54a.appspot.com",
          messagingSenderId: "883328664390",
          appId: "1:883328664390:web:4711e71aeece3b91719b47",
          measurementId: "G-9RB0SG4CQB"
        });
      } else {
        firebase.initializeApp({
          // Prod firebase
          apiKey: FIREBASE_API_KEY,
          authDomain: "app-808-cleanups.firebaseapp.com",
          databaseURL: "https://app-808-cleanups.firebaseio.com",
          projectId: "app-808-cleanups",
          storageBucket: "app-808-cleanups.appspot.com",
          messagingSenderId: "717078315087"
        });
      }

      db = firebase.firestore();
      this.hasBeenInitialized = true;
    } catch ({ message }) {
      console.warn("Failed to initialize Firebase!!!");
      // console.log(message);
    }
  }

  constructor() {
    this.establishFirebase();
    this.hasBeenInitialized = false;
  }

  /**
   * returns string if no connection or found empty parameter variables
   * returns false on error
   * returns data if success
   */
  async getSingleDocumentById(id, collectionName) {
    if (!id || !collectionName) {
      return false;
    }

    if (!this.hasBeenInitialized) {
      try {
        this.establishFirebase();

        if (!db) {
          return false;
        }
      } catch ({ message }) {
        return false;
      }
    }

    let response;
    try {
      let result = await db.collection(collectionName).doc(id).get();

      if (result.exists) {
        response = result.data();
        response.id = id;
      }

      return response;
    } catch ({ message }) {
      return false;
    }
  }

  async createSingleDocumentById(data, collectionName) {
    if (!data || !collectionName) {
      return EMPTY;
    }

    if (!this.hasBeenInitialized) {
      try {
        await this.establishFirebase();

        if (!db) {
          return NO_CONN;
        }
      } catch ({ message }) {
        return NO_CONN;
      }
    }

    try {
      data.create_date = new Date();
      data.last_update_date = new Date();
      let dataObj = Object.assign({}, data);
      delete dataObj.id; //we dont want to save the ID as a field, it will be the ID on the Firebase object

      await db.collection(collectionName).doc(data.id).set(dataObj);

      return true;
    } catch ({ message }) {
      console.log(message);
      return false;
    }
  }

  /**
   * returns string if no connection or found empty parameter variables
   * returns false on error
   * returns true if success
   */
  async updateSingleDocument(data, collectionName) {
    if (!data || !collectionName) {
      return false;
    }

    if (!this.hasBeenInitialized) {
      try {
        this.establishFirebase();

        if (!db) {
          return false;
        }
      } catch ({ message }) {
        return false;
      }
    }

    try {
      data.last_update_date = new Date();
      let dataObj = Object.assign({}, data);
      delete dataObj.id;

      let response = await db
        .collection(collectionName)
        .doc(data.id)
        .update(dataObj);

      if (response !== null) {
        //response is undefined if successfull...therefore it !== null
        //success
        return true;
      } else {
        return false;
      }
    } catch ({ message }) {
      //This catch will occur if something went wrong on .update() call, i.e document does not exist
      return false;
    }
  }

  async deleteSingleDocumentById(id, collectionName) {
    if (!id || !collectionName) {
      return false;
    }

    if (!this.hasBeenInitialized) {
      try {
        await this.establishFirebase();

        if (!db) {
          return false;
        }
      } catch ({ message }) {
        return false;
      }
    }

    try {
      await db.collection(collectionName).doc(id).delete();

      return true;
    } catch ({ message }) {
      return false;
    }
  }

  /**
   * returns string if no connection or found empty parameter variables
   * returns Error messages array if found error messages
   * returns true if success
   */

  //TODO create a function to save all or nothing

  async createList(itemList, collectioName) {
    if (isNullEmpty(itemList) || !collectioName) {
      return false;
    }

    let errorMsgs = [];

    for (let item of itemList) {
      if (!item) {
        continue;
      }

      let error = "";

      try {
        let resp;
        resp = await this.createSingleDocumentById(item, collectioName); //return t/f or a string of no connection or no data

        if (resp === NO_CONN || resp === EMPTY) {
          return false;
        }

        if (!resp) {
          error = "Error Creating: " + item;
          errorMsgs.push(error);
        }
      } catch ({ message }) {
        error = "Error Creating: " + item;
        errorMsgs.push(error);
      }
    } //end for loop

    if (errorMsgs.length > 0) {
      //we have error messages
      return errorMsgs;
    } else {
      return true; //success
    }
  }

  async updateUserPassword(newPw) {
    let user = firebase.auth().currentUser;
    try {
      await user.updatePassword(newPw);
      return true;
    } catch (error) {
      if (error) {
        return error.code;
      }
    }
  }

  returnFromCallback(resp) {
    returnCallback(resp);
  }

  getDatabase() {
    return db;
  }

  getDataArray(querySnapshot) {
    let data = [];
    querySnapshot.forEach(function (doc) {
      if (doc.exists) {
        const post = doc.data() || {};
        post.id = doc.id;
        data.push(post);
      }
    });
    return data;
  }

  getSingleDataFromArray(querySnapshot) {
    let data = [];
    querySnapshot.forEach(function (doc) {
      if (doc.exists) {
        const post = doc.data() || {};
        post.id = doc.id;
        data.push(post);
      }
    });
    if (data.length > 0) {
      return data[0];
    }
    return null;
  }

  async uploadMultiplePhotos(refId, photoMap) {
    //photo map is (key, photo)
    let uriMap = new Map();

    for (let item of photoMap) {
      if (!item) {
        continue;
      }

      let uri = await this.uploadSinglePhoto(refId + "_" + item[0], item[1]);

      if (uri) {
        uriMap.set(item[0], uri);
      }
    }
    return uriMap;
  }

  async uploadSinglePhoto(refId, imageFile) {
    console.log("uploadSinglePhoto", refId, imageFile);
    if (!refId || !imageFile) {
      return false;
    }

    let storageRef = null;
    try {
      // Create a reference, this will be our path to the photo
      storageRef = firebase.storage().ref(refId + ".jpg");
    } catch (error) {
      console.log(error);
      return false;
    }

    try {
      await storageRef.put(imageFile);

      let downloadURL = await firebase
        .storage()
        .ref(refId + ".jpg")
        .getDownloadURL();
      return downloadURL;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
}

Fire.shared = new Fire();
export default Fire;
