import { polygon } from "@turf/helpers";
import { point } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

export const OAHU = "O'ahu";
export const BIG_ISLAND = "Big Island";
export const MAUI = "Maui";
export const KAUAI = "Kauai";
export const MOLOKAI = "Moloka'i";
export const LANAI = "Lana'i";
export const NIIHAU = "Ni'ihau";
export const KAHOOLAWE = "Kaho'olawe";
export const PMNM = "Papahanaumokuakea Marine National Monument";

export const OAHU_BOUNDS = [
  [-158.358885, 21.792579],
  [-158.345829, 21.233169],
  [-157.609876, 21.234835],
  [-157.585795, 21.792579],
  [-158.358885, 21.792579]
];

export const BIG_ISLAND_BOUNDS = [
  [-156.186761, 20.329189],
  [-156.152077, 18.873504],
  [-154.647163, 18.857099],
  [-154.550577, 20.320874],
  [-156.186761, 20.329189]
];

export const MAUI_BOUNDS = [
  [-156.733903, 21.039202],
  [-156.717237, 20.919414],
  [-156.679065, 20.766496],
  [-156.529918, 20.757824],
  [-156.496955, 20.723181],
  [-156.466598, 20.601917],
  [-156.41972, 20.562627],
  [-155.913013, 20.601473],
  [-155.873896, 21.109111],
  [-156.733903, 21.039202]
];

export const KAUAI_BOUNDS = [
  [-159.849678, 22.255962],
  [-159.843781, 21.8421],
  [-159.23234, 21.8421],
  [-159.223887, 22.255962],
  [-159.849678, 22.255962]
];

export const MOLOKAI_BOUNDS = [
  [-157.322295, 21.241928],
  [-157.332166, 21.044508],
  [-156.698931, 21.041662],
  [-156.682828, 21.217876],
  [-157.322295, 21.241928]
];

export const LANAI_BOUNDS = [
  [-157.088414, 20.933139],
  [-156.790165, 20.944093],
  [-156.785541, 20.716887],
  [-157.088169, 20.722611],
  [-157.088414, 20.933139]
];

export const NIIHAU_BOUNDS = [
  [-160.271168, 22.03923],
  [-160.297227, 21.762505],
  [-160.040425, 21.766173],
  [-160.008347, 22.03923],
  [-160.271168, 22.03923]
];

export const KAHOOLAWE_BOUNDS = [
  [-156.521513, 20.610524],
  [-156.523106, 20.502622],
  [-156.710655, 20.493788],
  [-156.711333, 20.615361],
  [-156.521513, 20.610524]
];

export const PMNM_BOUNDS = [
  [-178.624306, 29.578767],
  [-178.624306, 22.469348],
  [-161.474903, 22.469348],
  [-161.474903, 29.578767],
  [-178.624306, 29.578767]
];

export const OAHU_POLYGON = polygon([OAHU_BOUNDS]);
export const BIG_ISLAND_POLYGON = polygon([BIG_ISLAND_BOUNDS]);
export const MAUI_POLYGON = polygon([MAUI_BOUNDS]);
export const KAUAI_POLYGON = polygon([KAUAI_BOUNDS]);
export const MOLOKAI_POLYGON = polygon([MOLOKAI_BOUNDS]);
export const LANAI_POLYGON = polygon([LANAI_BOUNDS]);
export const NIIHAU_POLYGON = polygon([NIIHAU_BOUNDS]);
export const KAHOOLAWE_POLYGON = polygon([KAHOOLAWE_BOUNDS]);
export const PMNM_POLYGON = polygon([PMNM_BOUNDS]);

export const listOfIslandPolygons = [
  { island: OAHU, polygon: OAHU_POLYGON },
  { island: BIG_ISLAND, polygon: BIG_ISLAND_POLYGON },
  { island: MAUI, polygon: MAUI_POLYGON },
  { island: KAUAI, polygon: KAUAI_POLYGON },
  { island: MOLOKAI, polygon: MOLOKAI_POLYGON },
  { island: LANAI, polygon: LANAI_POLYGON },
  { island: NIIHAU, polygon: NIIHAU_POLYGON },
  { island: KAHOOLAWE, polygon: KAHOOLAWE_POLYGON },
  { island: PMNM, polygon: PMNM_POLYGON }
];

export function isPointInPolygon(lat, long, poly) {
  let myPoint = point([long, lat]);
  let inPoly = booleanPointInPolygon(myPoint, poly);
  return inPoly;
}

export function getIslandForCoordinate(lat, long) {
  for (let item of listOfIslandPolygons) {
    if (isPointInPolygon(lat, long, item.polygon)) {
      return item.island;
    }
  }

  return null;
}
