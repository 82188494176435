export const EventConfirmationScreen = (props) => {
  return (
    <div className="container mt-5">
      <h1>Mahalo Nui Loa!</h1>
      <h3>
        Your event has been submitted to the 808 Cleanups staff for review!
        Expect a confirmation email shortly and check your messages on the 808
        Cleanups mobile app.
      </h3>
      <br />
      <a href="/">
        {" "}
        <button className="btn btn-primary" style={{ margin: 20 }}>
          Done
        </button>
      </a>
    </div>
  );
};
