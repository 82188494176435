import Fire from "./Fire";
import { NO_CONN } from "../util/CommonConstants";

var collectionName = "users";
var db;
var EMPTY = "Empty";

class UsersColl {
  constructor() {
    db = Fire.shared.getDatabase();
  }

  async getFirebaseDatabase() {
    if (!Fire.shared.hasBeenInitialized) {
      await Fire.shared.establishFirebase();
    }
    return Fire.shared.getDatabase();
  }

  async getUserByEmail(email) {
    if (!email) {
      return EMPTY;
    }

    if (!db) {
      db = await this.getFirebaseDatabase();
    }

    if (!db) {
      return NO_CONN;
    }

    let user = null;
    try {
      let results = await db
        .collection(collectionName)
        .where("email", "==", email)
        .get();

      user = Fire.shared.getSingleDataFromArray(results);
    } catch (err) {
      return false;
    }

    if (user) {
      return user;
    } else {
      return false;
    }
  }

  async getUser(id) {
    if (!id) {
      return EMPTY;
    }

    if (!db) {
      db = await this.getFirebaseDatabase();
    }

    if (!db) {
      return NO_CONN;
    }

    let user = null;
    try {
      let results = await db
        .collection(collectionName)
        .where("uid", "==", id)
        .get();

      user = Fire.shared.getSingleDataFromArray(results);
    } catch (err) {
      console.log(err);
      return false;
    }

    if (user) {
      return user;
    } else {
      console.log("No user found!");
      return false;
    }
  }

  async getUserEmail(id) {
    if (!id) {
      return EMPTY;
    }

    if (!db) {
      db = await this.getFirebaseDatabase();
    }

    if (!db) {
      return false;
    }

    let user = null;
    try {
      user = await Fire.shared.getSingleDocumentById(id, collectionName);
    } catch (err) {
      return false;
    }

    if (user) {
      return user.email;
    } else {
      return false;
    }
  }

  async updateUserOrganizations(id, orgs) {
    if (!id || !orgs) {
      return EMPTY;
    }

    if (!db) {
      db = await this.getFirebaseDatabase();
    }

    if (!db) {
      return NO_CONN;
    }

    try {
      await db.collection(collectionName).doc(id).update({
        organizations: orgs,
      });
    } catch (err) {
      console.log(err);
      return false;
    }
  }
}

UsersColl.shared = new UsersColl();

export default UsersColl;
