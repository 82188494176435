import React, { useState } from "react";
import Select from "react-select";
import { debrisTypeList, debrisValueMap } from "../../util/CommonConstants";

export const CleanupDebrisLog = ({ updateRecordList }) => {
  const [activeRecord, setActiveRecord] = useState({
    debris_type: "Trash",
    measurement: 0
  });
  const [records, setDebrisRecords] = useState([]);

  const handleDebrisTypeChange = (e) => {
    let record = Object.assign({}, activeRecord);
    record["debris_type"] = e.value;
    setActiveRecord(record);
  };

  const handleQuantityChange = (e) => {
    console.log(e);
    let record = Object.assign({}, activeRecord);
    record["measurement"] = parseFloat(e.target.value);
    setActiveRecord(record);
  };

  const handleCreateRecord = () => {
    if (activeRecord.debris_type && activeRecord.measurement) {
      let updatedRecords = [...records, Object.assign({}, activeRecord)];
      setDebrisRecords(updatedRecords);
      updateRecordList(updatedRecords);
    }
  };

  const handleRemoveRecord = (index) => {
    console.log(index);
    const updatedRecords = [...records];
    updatedRecords.splice(index, 1);
    setDebrisRecords(updatedRecords);
  };

  return (
    <div className="container mt-5">
      <h2>Debris Log</h2>
      <div className="row mb-3">
        <div className="col">
          <label htmlFor="debrisTypeInput" className="form-label">
            Debris Type
          </label>
          <Select
            className="basic-single"
            options={debrisTypeList.map((debrisType) => {
              return { value: debrisType, label: debrisType };
            })}
            onChange={handleDebrisTypeChange}
          />
        </div>
        <div className="col">
          <label htmlFor="quantityInput" className="form-label">
            {debrisValueMap[activeRecord.debris_type]}
          </label>
          <input
            type="number"
            className="form-control"
            id="quantityInput"
            value={activeRecord.measurement}
            onChange={handleQuantityChange}
          />
        </div>
      </div>
      <button className="btn btn-primary" onClick={handleCreateRecord}>
        Add Debris Record
      </button>

      {records.length > 0 ? (
        <h4 style={{ marginTop: 5 }}>Logged Debris Records</h4>
      ) : null}

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between"
        }}
      >
        {records.length > 0
          ? records.map((record, index) => (
              <div
                key={index}
                style={{ flexBasis: "48%", marginBottom: "20px" }}
              >
                <p>
                  Debris Type: {record.debris_type} <br />{" "}
                  {debrisValueMap[record.debris_type]}: {record.measurement}{" "}
                  <br />{" "}
                </p>
                <button
                  className="btn btn-danger"
                  onClick={() => handleRemoveRecord(index)}
                >
                  Remove
                </button>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};
