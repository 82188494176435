export function isNullEmpty(list) {
  return !list || list.length === 0;
}

export function getUserIdName(id) {
  if (!id) {
    return "";
  }

  let name = id;

  let nameSplit = name.split("_");

  if (nameSplit && nameSplit.length > 0) {
    name = nameSplit[0];
  }
  if (name) {
    return name.substring(0, 12);
  }

  return "";
}

export function createRandomId() {
  let rand = Math.random();

  let randStr = rand.toString();

  let randSplit = randStr.split(".");

  if (randSplit && randSplit.length === 2) {
    if (randSplit[1].length < 10) {
      rand = randSplit[1];
    } else {
      rand = randSplit[1].substring(0, 9);
    }
  }
  return rand;
}

export function arrayToFormattedString(array, lineInbetween) {
  if (!array || array.length === 0) {
    return "";
  }
  let displayStr = "";

  if (array) {
    array.forEach(function (type) {
      if (displayStr.length > 0) {
        displayStr += "\n" + (lineInbetween ? "\n" : "") + type;
      } else {
        displayStr += type;
      }
    });
  }
  return displayStr;
}
