export const CalendarScreen = () => {
    return (
        <div>
            <h1>Calendar</h1>

            <iframe title="calendar"
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Pacific%2FHonolulu&bgcolor=%23ffffff&showTitle=0&showTz=0&showPrint=0&showTabs=0&src=aTBuMXY4ZmJrODA5ZWh0bDk3ODBkbWNyazBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23009688" style={{border: 0, width: "95%", height: "40em"}}
            ></iframe>
        </div>
    );
};