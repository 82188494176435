import "./static/css/App.css";
import theme from "./theme";
import Router from "./Router";
import { ThemeProvider } from "@material-ui/styles";
import firebase from "firebase/app";
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("login detected");
      } else {
        console.log("logout detected");
      }
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
