import React, { useState } from "react";
import "./styles.css";
import CustomInput from "../../components/auth/CustomInput";
import Button from "../../components/Button";
import { login } from "../../util/Auth";
import { useHistory } from "react-router-dom";

export default function LoginScreen() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.currentTarget.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.currentTarget.value);
  };

  let userLogin = () => {
    login(email, password).then((success) => {
      console.log(success);
      if (success) {
        console.log("Logged in!");
        history.push("/");
      } else {
        //alert invalid login
      }
    });
  };

  return (
    <div className="LoginScreen">
      <h1>808 Cleanups Web App Login</h1>
      <form className="form">
        <CustomInput
          labelText="Email"
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          handleChange={handleEmailChange}
          type="text"
        />
        <CustomInput
          labelText="Password"
          id="password"
          formControlProps={{
            fullWidth: true
          }}
          handleChange={handlePasswordChange}
          type="password"
        />

        <Button
          onClick={userLogin}
          type="button"
          color="primary"
          className="form__custom-button"
        >
          Log in
        </Button>
      </form>
    </div>
  );
}
