/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { CleanupConfirmationScreen } from "./pages/cleanups/CleanupConfirmationScreen";
import { Footer } from "./components/Footer";
// import NavBar from "./components/NavBar";
import LoginScreen from "./pages/auth/LoginScreen";
import LogoutScreen from "./pages/auth/LogoutScreen";
import LandingScreen from "./pages/landing/LandingScreen";
import { isLogin } from "./util/Auth";
import { CleanupLogForm } from "./pages/cleanups/CleanupLogForm";
import { EventCreateForm } from "./pages/events/EventCreateForm";
import { EventConfirmationScreen } from "./pages/events/EventConfirmationScreen";
import { CalendarScreen } from "./pages/calendar/CalendarScreen";
import { AdminScreen } from "./pages/admin/AdminScreen";
import NavBar from "./components/NavBar";
import { OrganizationsScreen } from "./pages/organizations/OrganizationsScreen";
import { OrganizationJoinedScreen } from "./pages/organizations/OrganizationJoinedScreen";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default () => (
  <BrowserRouter>
    <NavBar />

    <div className="content">
      <Switch>
        <Route exact path="/login" component={LoginScreen} />
        <Route exact path="/logout" component={LogoutScreen}></Route>

        <PrivateRoute exact path="/" component={LandingScreen}></PrivateRoute>
        <PrivateRoute
          exact
          path="/cleanup/log"
          component={CleanupLogForm}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/cleanup/complete"
          component={CleanupConfirmationScreen}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/event/create"
          component={EventCreateForm}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/event/complete"
          component={EventConfirmationScreen}
        ></PrivateRoute>

        <PrivateRoute exact 
          path="/calendar" 
          component={CalendarScreen}
        ></PrivateRoute>

        <PrivateRoute exact 
          path="/organizations" 
          component={OrganizationsScreen}
        ></PrivateRoute>

        <PrivateRoute exact 
          path="/organizations/joined" 
          component={OrganizationJoinedScreen}
        ></PrivateRoute>

        <PrivateRoute exact 
        path="/admin"
         component={AdminScreen}
         ></PrivateRoute>

      </Switch>
    </div>
    <Footer />
  </BrowserRouter>
);
