import OrganizationsColl from "../../firebase/OrganizationsColl";
import { useEffect, useState } from "react";
import logo from "../../static/img/logo.png";
import "../../static/css/OrganizationsScreen.css";

export const OrganizationJoinedScreen = () => {
    // TODO: Extract organization from URL and display a screen welcoming the user to the organization
    let urlParams = new URLSearchParams(window.location.search);
    const orgId = urlParams.get("orgid");

    const [organization, setOrganization] = useState(null);

    useEffect(() => {
        async function fetchOrg() {
            let org = await OrganizationsColl.shared.getOrganizationById(orgId);
            setOrganization(org);
        }
        fetchOrg();
    });

    function returnToOrganizations() {
        window.location.href = "/organizations";
    }

    return (
        <div className="summary">
            {organization?.org_logo && organization?.org_logo.length > 0 ? (
                <img src={organization?.org_logo} alt="logo" />
              ) : (
                <img src={logo} alt="logo" />
            )}
            
            <h2>You joined {organization?.org_name}!</h2>

            <p>{organization?.org_description}</p>

            <p>{organization?.org_name} is excited to have you join the team!</p>

            <button onClick={returnToOrganizations}>Return to Organizations</button>
        </div>
    );
};