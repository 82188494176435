import firebase from "firebase/app";
import "firebase/auth";
import UsersColl from "../firebase/UsersColl";

export const TOKEN_KEY = "userInfo";

export const login = (email, password) => {
  if (isLogin()) {
    return true;
  }

  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((response) => {
      var user = response.user;
      return UsersColl.shared.getUser(user.uid).then((fireUser) => {
        console.log(fireUser);
        localStorage.setItem(TOKEN_KEY, JSON.stringify(fireUser));
        return true;
      });
    });
};

export const logout = () => {
  return firebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.removeItem(TOKEN_KEY);
    });
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) return true;
  else return false;
};
