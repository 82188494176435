import { CLEANUP_COLL } from "../util/CommonConstants";
import Fire from "./Fire";

var collectionName = CLEANUP_COLL;
var db;

export class CleanupModel {
  id;
  user_id;
  organization;
  user_name;
  island;
  geolocation;
  site_name;
  group_id;
  group_name;
  start_date;
  create_date;
  last_update_date;
  total_time;
  distance;
  num_volunteers;
  activities;
  debris_list;
  adopted_site_id;
  notes;
  is_deleted;
}

class CleanupColl {
  constructor() {
    db = Fire.shared.getDatabase();
  }

  async getFirebaseDatabase() {
    if (!Fire.shared.hasBeenInitialized()) {
      await Fire.shared.establishFirebase();
    }
    return Fire.shared.getDatabase();
  }

  async getCleanupByID(id) {
    if (!id) {
      return false;
    }

    if (!db) {
      db = await this.getFirebaseDatabase();
    }

    if (!db) {
      return false;
    }

    let cleanup = await Fire.shared.getSingleDocumentById(id, collectionName);

    if (cleanup) {
      return cleanup;
    } else {
      return false;
    }
  }

  async getCleanupsByUser(userId) {
    if (!userId) {
      return false;
    }

    if (!db) {
      db = await this.getFirebaseDatabase();
    }

    if (!db) {
      return false;
    }

    let results = await db
      .collection(collectionName)
      .where("user_id", "==", userId)
      .orderBy("start_date", "desc")
      .get();

    if (results) {
      return Fire.shared.getDataArray(results);
    } else {
      return [];
    }
  }

  async getCleanupsByDate(endDate) {
    return [];
  }

  async updateCleanup(cleanup) {
    if (!cleanup) {
      return false;
    }

    var response = await Fire.shared.updateSingleDocument(
      cleanup,
      collectionName
    );

    if (response === true) {
      return true;
    } else {
      return false;
    }
  }

  async createCleanup(cleanup, approved = true) {
    if (!cleanup) {
      return false;
    }

    cleanup.organization = "808_cleanups";

    let approvedCollName = collectionName;
    if (!approved) approvedCollName = collectionName + "_unapproved";

    console.log(cleanup);

    var response = await Fire.shared.createSingleDocumentById(
      cleanup,
      approvedCollName
    );
    console.log(response);

    if (response === true) {
      return true;
    } else {
      console.log("Error creating cleanup");
      return false;
    }
  }
}

CleanupColl.shared = new CleanupColl();

export default CleanupColl;
