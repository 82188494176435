export function Footer() {
  return (
    <ul
      style={{
        padding: 25,
        marginTop: 25,
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        textAlign: "center"
      }}
    ></ul>
  );
}
