import React, { useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import { useEffect } from "react";

import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [16, 40]
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapComponent = ({ handleLocationUpdate }) => {
  const [position, setPosition] = useState(null);
  const [location, setLocation] = useState([21.453026, -157.976436]);
  const mapRef = useRef(null);

  const recenterMap = () => {
    if (mapRef.current) {
      mapRef.current.setView(location, 11);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            console.log(
              "Please enable location services to allow us to determine your location."
            );
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  function success(pos) {
    let lat = pos.coords.latitude;
    let lon = pos.coords.longitude;
    mapRef.current.setView([lat, lon], 11);
    setLocation([lat, lon]);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  const Markers = () => {
    const map = useMapEvents({
      click(e) {
        setPosition([e.latlng.lat, e.latlng.lng]);
        handleLocationUpdate(e.latlng.lat, e.latlng.lng);
      }
    });

    return position ? <Marker position={position} interactive={false} /> : null;
  };

  return (
    <div className="map-container" style={{ textAlign: "left" }}>
      <MapContainer
        center={location} // Default center position (User location)
        zoom={7}
        style={{
          height: "400px",
          width: "100%",
          position: "relative",
          zIndex: "0"
        }}
        ref={mapRef}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Markers />
      </MapContainer>
      <button
        style={{
          marginTop: 10,
          padding: "0px 16px",
          background: "#fff",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          cursor: "pointer"
        }}
        onClick={recenterMap}
      >
        Recenter Map
      </button>
      <hr />
    </div>
  );
};

export default MapComponent;
